<template>
  <v-card @click="edit" flat class="cart-info-card h-100 px-3 py-3">
    <div
      class="d-flex justify-space-between align-center h-100 cart-info-card-cnt"
    >
      <div class="d-flex flex-row align-center">
        <v-icon alt="Immagine servizio" v-if="icon" class="pr-3">{{
          icon
        }}</v-icon>
        <v-img
          v-else-if="imgSrc"
          :src="imgSrc"
          contain
          class="img-info-card"
          alt="Immagine servizio"
          height="33"
          width="33"
        ></v-img>
        <div class="text-body-2">
          <div class="text font-weight-bold text-uppercase">{{ text }}</div>
          <div class="value">{{ infoValue }}</div>
        </div>
      </div>
      <v-icon small class="edit" color="default" right v-if="editable">
        $edit
      </v-icon>
    </div>
  </v-card>
</template>
<style lang="scss">
.cart-info-card {
  border: 1px solid $border-color;
  background-color: var(--v-grey-lighten1) !important;
  border-radius: 6px !important;
  .img-container {
    margin-right: 8px;
  }
  @mixin hide-img-icon {
    .icon-info-card,
    .img-info-card {
      display: none;
    }
    .img-container {
      display: none;
      margin-right: 0px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    @include hide-img-icon;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    @include hide-img-icon;
  }
}
.is-cordova {
  .cart-info-card-wrapper {
    .cart-info-card {
      .cart-info-card-cnt {
        width: 100%;
      }
    }
  }
}
</style>
<script>
export default {
  name: "CartInfoCard",
  props: {
    icon: { type: String, required: false },
    imgSrc: { type: String, required: false },
    text: { type: String, required: true },
    infoValue: { type: String, required: true },
    editable: { type: Boolean, default: true }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
